// Import React first, separately - this ensures it's fully initialized before anything else
import * as React from 'react';
// Import Clarity
import Clarity from '@microsoft/clarity';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';

// Use a more significant delay to ensure all modules are fully loaded
// This is especially important for deployed environments where bundled code might have initialization issues
document.addEventListener('DOMContentLoaded', function () {
  // Further delay rendering to ensure all modules are initialized
  setTimeout(() => {
    // Further debug checks, error handling, and Google Analytics setup
    try {
      const gaId = import.meta.env.VITE_GA_ID;
      const isDebug = import.meta.env.VITE_GA_DEBUG === 'true';
 
      // Initialize Google Analytics (your existing code)
      const trackPageView = (url: string) => {
        if (window.gtag && gaId) {
          const config: { page_path: string } = {
            page_path: url,
          };
          if (isDebug) {
            // @ts-ignore - debug_mode is a valid Google Analytics config option
            config.debug_mode = true;
          }
          window.gtag('config', gaId, config);
        }
      };

      if (window.gtag && gaId) {
        // Track initial page view
        trackPageView(window.location.pathname);
      
        // Track page load time
        window.addEventListener('load', () => {
          const timing = performance.timing;
          const pageLoadTime = timing.loadEventEnd - timing.navigationStart;
          window.gtag('event', 'page_load', {
            event_category: 'Performance',
            event_label: 'Page Load Time',
            value: Math.round(pageLoadTime),
          });
        });
      }

      // Initialize Clarity for your project
      const clarityProjectId = 'r16hrzmyyq'; // Buraya Clarity projenizin ID'sini ekleyin
      Clarity.init(clarityProjectId);

      console.log('Initializing React application...');
      const rootElement = document.getElementById('root');
      if (!rootElement) {
        console.error('Root element not found!');
        return;
      }
 
      const root = ReactDOM.createRoot(rootElement);
      root.render(
        <React.StrictMode>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </React.StrictMode>
      );
      console.log('React application initialized successfully');
    } catch (error) {
      console.error('Error initializing application:', error);
    }
  }, 50); // Use a slightly longer timeout (50ms) to ensure everything is loaded
});
